<template>
  <section
    v-editable="blok"
    v-bind="{ ...(blok.id ? { id: blok.id } : {}) }"
    class="grid auto-cols-fr gap-4 md:grid-flow-col"
    :class="[marginClasses, { container: blok.in_container }]"
  >
    <StoryblokComponent
      v-for="column in blok.columns"
      :key="column._uid"
      :blok="column"
      :sizes="sizes"
    />
  </section>
</template>

<script setup lang="ts">
import type { SbGrid } from '~/storyblok/types/storyblok'

const props = defineProps({
  blok: {
    type: Object as PropType<SbGrid>,
    required: true,
  },
})
const { marginClasses } = useStoryblokMargins(props.blok)

const sizes = computed(() => {
  const vw = Math.ceil(100 / (props?.blok?.columns?.length || 1))

  return `xs:100vw sm:100vw md:100vw lg:${vw}vw  xl:${vw}vw  xxl:${vw}vw 2xl:${vw}vw `
})

defineOptions({ name: 'CmsGrid' })
</script>
